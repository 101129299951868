<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  
  },
  data() {
    return {
        day: null,
      payid: [],
      final_total: [],
      billing_invoices: [],
      customerData: [],
      page: 1,
      status: 1,
      limit: 20,
      tot_pages: 0,
      searchBillingInvoices: null,
      BillingInvoicesSearchMode: false,
      resultStatus: null,
      searchModel: "",
      billing_invoices_id: [],
      x: [],
      countries: [],
      search: "",
      selectedCustomers: null,
      bill:[],
      allSelected:false,
      data:{},
      branches:[],
      neighborhoods:[],
      total:0,
      fromCheckNeighborhood:false
    };
  },
  methods: {
    getNeighborhood(){
        this.http.get("neighborhoods").then(res=>{
            this.neighborhoods = res.data
        })
    },
    searchNeighborhood(){
    console.log(this.data);
    this.http.post("billing-invoices/report",this.data).then(res=>{
      console.log(res.data[0]);
      if(res.data[0].customer == null){
        this.customerData = []
      }
      else{
        console.log("Else");
        this.customerData = res.data
      }
      console.log(this.customerData);
      this.data = {};
      this.total = this.customerData.map(a=>a?.customer?.tariff).reduce((a,b)=>parseInt(a)+parseInt(b.fees),0)
      console.log(this.totalT);
    })
   },
    enableFromTo(){
      console.log(this.fromCheckNeighborhood);
      if(this.fromCheckNeighborhood == true){
        console.log("from");
        document.getElementById('oneDateNeighborhood').setAttribute('disabled','disabled')
        document.getElementById('fromNeighborhood').removeAttribute('disabled');
        document.getElementById('toNeighborhood').removeAttribute('disabled');
        delete this.data['date']
      }
      if(this.fromCheckNeighborhood == false){
        console.log("one");
        document.getElementById('oneDateNeighborhood').removeAttribute('disabled');
        document.getElementById('fromNeighborhood').setAttribute('disabled','disabled')
        document.getElementById('toNeighborhood').setAttribute('disabled','disabled')
        delete this.data['from']
        delete this.data['to']
      }
    },
  },
  created() {
   this.getNeighborhood();
  },
};
</script>

<template>
 
    <form class="row gx-3 gy-2 align-items-center mb-4" @submit.prevent="searchNeighborhood()">
        <div class="col">
            <label class="inline" for="specificSizeInputName">{{$t("report.select-neighborhood")}}</label>
            <select required v-model="data.neighborhood_id" class="form-select">
              <option v-for="neighborhood in neighborhoods" :key="neighborhood" :value="neighborhood.id">{{neighborhood.name}}</option>
            </select>
          </div>
        <div class="col">
            <label class="inline" for="oneDateNeighborhood">{{$t("report.date")}}</label>
            <input  v-model="data.date" type="date" class="form-control oneDate" id="oneDateNeighborhood">
        </div>
        <div class="col">
            <div class="form-check" style="display:flex;justify-content:center;top: 15px">
                <input v-model="fromCheckNeighborhood" @change="enableFromTo()" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label v-if="$i18n.locale == 'en'" style="margin-left: 5px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
                <label v-if="$i18n.locale == 'ar'" style="margin-right: 25px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
              </div>
        </div>
        <div class="col">
            <label class="inline" for="fromNeighborhood">{{ $t("report.from") }}</label>
            <input  disabled v-model="data.from" type="date" class="form-control" id="fromNeighborhood">
        </div>
         <div class="col">
            <label class="inline" for="toNeighborhood">{{ $t("report.to") }}</label>
            <input disabled v-model="data.to" type="date" class="form-control fromto" id="toNeighborhood">
        </div>
        <div class="col-auto" style="top:14px">
          <button type="submit" class="btn btn-primary">{{ $t("popups.search") }}</button>
        </div>
      </form>
   

      
      <table class="table mt-5">
        <thead>
          <tr class=" text-light text-center" style="background-color: #2a3042 !important;">
            <th scope='col'>{{ $t('customers.id') }}</th>
            <th scope='col'>{{ $t('customers.tariff') }}</th>
            <th scope='col'>{{ $t('customers.neighborhood') }} </th>
            <th scope='col'>{{ $t('customers.name') }}</th>
            <th scope='col'>{{ $t('customers.account_no') }}</th>
            <th scope='col'>{{ $t('customers.meter_no') }}</th>
            <th scope='col'>{{ $t('customers.block') }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in customerData" :key="data" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ data?.customer?.tariff?.fees }}</td>
          <td>{{ data?.customer?.neighborhood?.name }}</td>
          <td>{{ data.customer?.name }}</td>
          <td>{{ data.customer?.account_no }}</td>
          <td>{{ data.customer?.meter_no }}</td>
          <td>{{ data.customer?.block }}</td>
        </tr>
      </tbody>
      <tfoot v-if="total > 0">
        <tr>
            <td class="text-end" colspan="7">{{ $t("report.total") }} : {{total.toLocaleString()}}</td>
        </tr>
      </tfoot>
    </table>
</template>