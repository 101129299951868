<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  
  },
  data() {
    return {
        day: null,
      payid: [],
      final_total: [],
      billing_invoices: [],
      customerData: [],
      page: 1,
      status: 1,
      limit: 20,
      tot_pages: 0,
      searchBillingInvoices: null,
      BillingInvoicesSearchMode: false,
      resultStatus: null,
      searchModel: "",
      billing_invoices_id: [],
      x: [],
      countries: [],
      search: "",
      selectedCustomers: null,
      bill:[],
      allSelected:false,
      data:{},
      branches:[],
      total:0,
      fromCheckBranch:false
    };
  },
  methods: {
    getBranch(){
        this.http.get("branches",{
            limit: this.limit,
            page: this.page,
        }).then(res=>{
            this.branches = res.data
        })
    },
   searchBranch(){
    this.total = 0
    console.log(this.data);
    this.http.post("billing-invoices/report",this.data).then(res=>{
      if(res.data[0].customer == null){
        this.customerData = []
      }
      else {
        this.customerData = res.data
      }
      console.log(this.customerData);
      this.data = {};
      this.total = this.customerData.map(a=>a?.customer?.tariff).reduce((a,b)=>parseInt(a)+parseInt(b.fees),0)
      console.log(this.totalT);
    })
   },
    enableFromTo(){
      console.log(this.fromCheckBranch);
      if(this.fromCheckBranch == true){
        console.log("from");
        document.getElementById('oneDateBranch').setAttribute('disabled','disabled')
        document.getElementById('fromBranch').removeAttribute('disabled');
        document.getElementById('toBranch').removeAttribute('disabled');
        delete this.data['date']
      }
      if(this.fromCheckBranch == false){
        console.log("one");
        document.getElementById('oneDateBranch').removeAttribute('disabled');
        document.getElementById('fromBranch').setAttribute('disabled','disabled')
        document.getElementById('toBranch').setAttribute('disabled','disabled')
        delete this.data['from']
        delete this.data['to']
      }
    },
  },
  created() {
   this.getBranch();
  },
};
</script>

<template>
 
    <form class="row gx-3 gy-2 align-items-center mb-4" @submit.prevent="searchBranch()">
        <div class="col">
            <label class="inline" for="specificSizeInputName">{{$t("report.select-branch")}}</label>
            <select required v-model="data.branch_id" class="form-select">
              <option value="*">All</option>
              <option v-for="branch in branches" :key="branch" :value="branch.id">{{branch.name}}</option>
            </select>
          </div>
        <div class="col">
            <label class="inline" for="oneDateBranch">{{$t("report.date")}}</label>
            <input v-model="data.date" type="date" class="form-control oneDate" id="oneDateBranch">
        </div>
        <div class="col">
            <div class="form-check" style="display:flex;justify-content:center;top: 15px">
                <input v-model="fromCheckBranch" @change="enableFromTo()" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label v-if="$i18n.locale == 'en'" style="margin-left:5px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
                <label v-if="$i18n.locale == 'ar'" style="margin-right:25px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
              </div>
        </div>
        <div class="col">
            <label class="inline" for="fromBranch">{{ $t("report.from") }}</label>
            <input disabled v-model="data.from" type="date" class="form-control" id="fromBranch">
        </div>
         <div class="col">
            <label class="inline" for="toBranch">{{ $t("report.to") }}</label>
            <input disabled v-model="data.to" type="date" class="form-control fromto" id="toBranch">
        </div>
        <div class="col-auto" style="top:14px">
          <button type="submit" class="btn btn-primary">{{ $t("popups.search") }}</button>
        </div>
      </form>
   

      
      <table class="table mt-5">
        <thead>
          <tr class=" text-light text-center" style="background-color: #2a3042 !important;">
            <th scope='col'>{{ $t('customers.id') }}</th>
            <th scope='col'>{{ $t('customers.tariff') }}</th>
            <th scope='col'>{{ $t('customers.neighborhood') }} </th>
            <th scope='col'>{{ $t('customers.name') }}</th>
            <th scope='col'>{{ $t('customers.account_no') }}</th>
            <th scope='col'>{{ $t('customers.meter_no') }}</th>
            <th scope='col'>{{ $t('customers.block') }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in customerData" :key="data" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ data?.customer?.tariff?.fees }}</td>
          <td>{{ data?.customer?.neighborhood?.name }}</td>
          <td>{{ data.customer?.name }}</td>
          <td>{{ data.customer?.account_no }}</td>
          <td>{{ data.customer?.meter_no }}</td>
          <td>{{ data.customer?.block }}</td>
        </tr>
      </tbody>
      <tfoot v-if="total > 0">
        <tr>
            <td class="text-end" colspan="7">{{ $t("report.total") }} : {{total.toLocaleString()}}</td>
        </tr>
      </tfoot>
    </table>
   

   
   
    <!--   Apps  Pagination     -->
    <ul v-if="tot_pages >=20" class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->

</template>