<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import BillingbyBranch from "./Billing by branch/BillingbyBranch.vue";
import BillingbyNeighborhood from "./Billing by neighborhood/BillingbyNeighborhood.vue";
import BillingbyStatus from "./Billing by status/BillingbyStatus.vue";
import BillingbyCustomerVue from './Billing by customer/BillingbyCustomer.vue';
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    BillingbyBranch,
    BillingbyNeighborhood,
    BillingbyStatus,
    BillingbyCustomerVue
  },
  data() {
    return {
   

    };
  },
  methods: {


  },

  created() {
   



  }
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('report.billings-report')"  />
    <b-tabs   :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active>
          <template v-slot:title>
            <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block">{{$t("report.billings-by-branch")}}</span>
          </template>
          <billingby-branch></billingby-branch>
        <!-- end emp tab-->
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block">{{$t("report.billings-by-neighborhood")}} </span>
          </template>
          <BillingbyNeighborhood></BillingbyNeighborhood>
  </b-tab>
  
  <b-tab>
      <template v-slot:title>
        <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">{{$t("report.billings-by-status")}} </span>
      </template>
      <BillingbyStatus></BillingbyStatus>
  </b-tab>

  <b-tab>
    <template v-slot:title>
      <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
        <i class="fas fa-home"></i>
      </span>
      <span class="d-none d-sm-inline-block">{{$t("report.billings-by-customer")}} </span>
    </template>
    <BillingbyCustomerVue></BillingbyCustomerVue>
</b-tab>
  </b-tabs>
  </Layout>
</template>
<style scoped>
.close{
  position: relative;
      right: 317px;
        top: 6px;
      font-size: 15px;}

</style>




